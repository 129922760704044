<template>
  <div class="kol-detail-page" v-loading="loading">
    <div class="breadcrumb-container">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/kol/list' }">达人列表</el-breadcrumb-item>
        <el-breadcrumb-item>达人详情</el-breadcrumb-item>
        <el-breadcrumb-item v-if="kolDetail">{{ kolDetail.nick_name }}</el-breadcrumb-item>
        <el-breadcrumb-item v-else>加载中...</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="kol-detail-container" v-if="kolDetail">
      <div class="kol-detail-left">
        <!-- 第一个卡片：头像、名称、ID、更新日期 -->
        <div class="kol-data-section">
          <div class="kol-basic-info">
            <el-image class="kol-avatar" :src="kolDetail.avatar_uri" fit="cover">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <div class="kol-info-content">
              <h2 class="kol-name">{{ kolDetail.nick_name }}</h2>
              <p class="kol-id">ID: {{ kolDetail.star_id }} <i class="el-icon-copy-document" @click="copyStarId"></i>
              </p>
              <div class="kol-tags" v-if="kolDetail.tags && kolDetail.tags.length">
                <el-tag size="mini" v-for="(tag, index) in kolDetail.tags" :key="index" class="kol-tag">{{ tag
                }}</el-tag>
              </div>
            </div>
          </div>

          <div class="kol-update-time">
            <span class="info-label">数据更新日期：</span>
            <span>{{ kolDetail.updated_at }}</span>
          </div>

          <el-button type="primary" size="small" class="platform-btn" @click="openPlatformProfile(kolDetail)">
            查看平台主页
          </el-button>
        </div>

        <!-- 第二个卡片：粉丝数据 -->
        <div class="kol-data-section">
          <div class="section-title">粉丝数据</div>
          <div class="fans-data-grid">
            <div class="fans-data-item">
              <div class="fans-data-label">粉丝数</div>
              <div class="fans-data-value">{{ formatNumber(kolDetail.follower) }}</div>
            </div>
            <div class="fans-data-item">
              <div class="fans-data-label">互动率</div>
              <div class="fans-data-value">{{ formatPercentage(kolDetail.interact_rate_within_30d) }}</div>
            </div>
            <div class="fans-data-item">
              <div class="fans-data-label">涨粉率</div>
              <div class="fans-data-value">{{ kolDetail.fans_increment_rate_within_15d ?
                formatPercentage(kolDetail.fans_increment_rate_within_15d) : '-' }}</div>
            </div>
            <div class="fans-data-item">
              <div class="fans-data-label">涨粉量</div>
              <div class="fans-data-value">{{ formatNumber(kolDetail.fans_increment_within_15d) }}</div>
            </div>
          </div>
        </div>

        <!-- 第三个卡片：平台、性别、地区、达人类型、内容主题 -->
        <div class="kol-data-section">
          <div class="section-title">达人信息</div>
          <div class="kol-info-grid">
            <div class="kol-info-item">
              <div class="info-item-label">平台</div>
              <div class="info-item-value">{{ getPlatformName(kolDetail.plat) }}</div>
            </div>
            <div class="kol-info-item">
              <div class="info-item-label">性别</div>
              <div class="info-item-value">{{ kolDetail.gender === 1 ? '男' : kolDetail.gender === 2 ? '女' : '未知' }}
              </div>
            </div>
            <div class="kol-info-item">
              <div class="info-item-label">地区</div>
              <div class="info-item-value">
                {{ getLocationDisplay(kolDetail.province, kolDetail.city) }}
              </div>
            </div>
            <div class="kol-info-item">
              <div class="info-item-label">达人类型</div>
              <div class="info-item-value">{{ getMainCategory(kolDetail.tags_relation) }}</div>
            </div>
          </div>

          <div class="content-themes" v-if="formatContentThemes(kolDetail.content_theme_labels_180d).length">
            <div class="content-themes-label">内容主题</div>
            <div class="tags-wrapper">
              <el-tag v-for="(tag, index) in formatContentThemes(kolDetail.content_theme_labels_180d)" :key="index" size="small" style="margin-right: 5px; margin-bottom: 5px;">
                {{ tag }}
              </el-tag>
            </div>
          </div>

          <div class="sub-categories" v-if="getTagsSubCategories(kolDetail.tags_relation)">
            <div class="sub-categories-label">子类别：</div>
            <div class="sub-categories-value">{{ getTagsSubCategories(kolDetail.tags_relation) }}</div>
          </div>
        </div>

        <!-- 第四个卡片：报价 -->
        <div class="kol-data-section">
          <div class="section-title">达人服务报价</div>
          <div v-if="kolDetail.plat === 4">
            <!-- B站价格显示 -->
            <div class="price-item" v-if="kolDetail.price_1_20 && kolDetail.price_1_20 !== 0">
              <span class="price-label">定制视频：</span>
              <span class="price-value">¥{{ kolDetail.price_1_20 }}</span>
            </div>
            <div class="price-item" v-if="kolDetail.price_20_60 && kolDetail.price_20_60 !== 0">
              <span class="price-label">植入视频：</span>
              <span class="price-value">¥{{ kolDetail.price_20_60 }}</span>
            </div>
            <div class="price-item" v-if="kolDetail.price_60 && kolDetail.price_60 !== 0">
              <span class="price-label">直发动态：</span>
              <span class="price-value">¥{{ kolDetail.price_60 }}</span>
            </div>
            <div class="price-item" v-if="kolDetail.price_180 && kolDetail.price_180 !== 0">
              <span class="price-label">转发动态：</span>
              <span class="price-value">¥{{ kolDetail.price_180 }}</span>
            </div>
          </div>
          <div v-else-if="kolDetail.plat === 2">
            <!-- 小红书价格显示 -->
            <div class="price-item" v-if="kolDetail.price_1_20 && kolDetail.price_1_20 !== 0">
              <span class="price-label">图文报价：</span>
              <span class="price-value">¥{{ kolDetail.price_1_20 }}</span>
            </div>
            <div class="price-item" v-if="kolDetail.price_20_60 && kolDetail.price_20_60 !== 0">
              <span class="price-label">视频报价：</span>
              <span class="price-value">¥{{ kolDetail.price_20_60 }}</span>
            </div>
          </div>
          <div v-else>
            <!-- 快手、抖音价格显示 -->
            <div class="price-item" v-if="kolDetail.price_1_20 && kolDetail.price_1_20 !== 0">
              <span class="price-label">1-20s：</span>
              <span class="price-value">¥{{ kolDetail.price_1_20 }}</span>
            </div>
            <div class="price-item" v-if="kolDetail.price_20_60 && kolDetail.price_20_60 !== 0">
              <span class="price-label">20-60s：</span>
              <span class="price-value">¥{{ kolDetail.price_20_60 }}</span>
            </div>
            <div class="price-item" v-if="kolDetail.price_60 && kolDetail.price_60 !== 0">
              <span class="price-label">60s以上：</span>
              <span class="price-value">¥{{ kolDetail.price_60 }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="kol-detail-right">
        <!-- 标签页 -->
        <el-tabs v-model="activeTab" class="kol-tabs" @tab-click="handleTabChange">
          <!-- 达人概览标签页 -->
          <el-tab-pane label="达人概览" name="overview">
            <!-- 商业能力数据 -->
            <div class="kol-data-section">
              <div class="section-title">商业能力数据</div>
              <div class="data-item">
                <span class="data-label">互动率：</span>
                <span class="data-value">{{ formatPercentage(kolDetail.interact_rate_within_30d) }}</span>
              </div>
              <div class="data-item">
                <span class="data-label">完播率：</span>
                <span class="data-value">{{ formatPercentage(kolDetail.play_over_rate_within_30d) }}</span>
              </div>
              <div class="data-item">
                <span class="data-label">预期CPM：</span>
                <span class="data-value">{{ kolDetail.expected_cpm }}</span>
              </div>
              <div class="data-item">
                <span class="data-label">行业传播指数：</span>
                <span class="data-value">{{ kolDetail.industry_spread_index }}</span>
              </div>
              <div class="data-item">
                <span class="data-label">指数：</span>
                <span class="data-value">{{ kolDetail.star_index }}</span>
              </div>
            </div>

            <!-- 作品列表 -->
            <div class="kol-data-section">
              <div class="section-title">作品列表</div>
              <el-table v-loading="videosLoading" :data="videosList" style="width: 100%" border stripe size="small">
                <el-table-column prop="title" label="标题" min-width="250">
                  <template slot-scope="scope">
                    <div class="video-content-cell" :class="{ 'no-themes': !formatContentThemes(scope.row.content_theme_labels).length }">
                      <div class="video-title-row">
                        <el-tooltip :content="scope.row.title" placement="top" :disabled="scope.row.title.length < 20">
                          <span class="video-title" @click="openVideoUrl(scope.row)">{{ scope.row.title }}</span>
                        </el-tooltip>
                        <el-button type="text" size="mini" icon="el-icon-video-play" @click="openVideoUrl(scope.row)">播放</el-button>
                      </div>
                      <div class="video-themes-row" v-if="formatContentThemes(scope.row.content_theme_labels).length">
                        <el-tag v-for="(theme, index) in formatContentThemes(scope.row.content_theme_labels)" :key="index" size="mini" style="margin-right: 5px; margin-bottom: 5px;">
                          {{ theme }}
                        </el-tag>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="watch_cnt" label="播放量" width="100">
                  <template slot="header">
                    <div class="table-header-with-sort">
                      <span>播放量</span>
                      <el-dropdown @command="handleSortCommand" trigger="click" size="small">
                        <span class="el-dropdown-link">
                          <i class="el-icon-sort"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="watch_cnt,desc"><i class="el-icon-sort-down"></i></el-dropdown-item>
                          <el-dropdown-item command="watch_cnt,asc"><i class="el-icon-sort-up"></i></el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    {{ formatNumber(scope.row.watch_cnt) }}
                  </template>
                </el-table-column>
                <el-table-column prop="like_cnt" label="点赞数" width="100">
                  <template slot="header">
                    <div class="table-header-with-sort">
                      <span>点赞数</span>
                      <el-dropdown @command="handleSortCommand" trigger="click" size="small">
                        <span class="el-dropdown-link">
                          <i class="el-icon-sort"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="like_cnt,desc"><i class="el-icon-sort-down"></i></el-dropdown-item>
                          <el-dropdown-item command="like_cnt,asc"><i class="el-icon-sort-up"></i></el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    {{ formatNumber(scope.row.like_cnt) }}
                  </template>
                </el-table-column>
                <el-table-column prop="comment_cnt" label="评论数" width="100">
                  <template slot="header">
                    <div class="table-header-with-sort">
                      <span>评论数</span>
                      <el-dropdown @command="handleSortCommand" trigger="click" size="small">
                        <span class="el-dropdown-link">
                          <i class="el-icon-sort"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="comment_cnt,desc"><i class="el-icon-sort-down"></i></el-dropdown-item>
                          <el-dropdown-item command="comment_cnt,asc"><i class="el-icon-sort-up"></i></el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    {{ formatNumber(scope.row.comment_cnt) }}
                  </template>
                </el-table-column>
                <el-table-column prop="finish_rate" label="完播率" width="100"></el-table-column>
                <el-table-column prop="interact_rate" label="互动率" width="100"></el-table-column>
                <el-table-column prop="create_time" label="发布时间" width="150">
                  <template slot="header">
                    <div class="table-header-with-sort">
                      <span>发布时间</span>
                      <el-dropdown @command="handleSortCommand" trigger="click" size="small">
                        <span class="el-dropdown-link">
                          <i class="el-icon-sort"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="create_time,desc"><i class="el-icon-sort-down"></i></el-dropdown-item>
                          <el-dropdown-item command="create_time,asc"><i class="el-icon-sort-up"></i></el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </el-table>

              <div class="pagination-container">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagination.page" :page-sizes="[10, 20, 50, 100]" :page-size="pagination.page_size" layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>

          <!-- 粉丝画像标签页 -->
          <el-tab-pane label="粉丝画像" name="fans">
            <!-- 粉丝画像数据 -->
            <div v-if="kolDetail.distribution_data && hasAnyDistributionData()">
              <div class="fans-portrait-grid">
                <!-- 性别分布 -->
                <div class="kol-data-section" v-if="hasDistributionData('gender_distribution')">
                  <div class="section-title">性别分布</div>
                  <div id="gender-chart" class="chart-container"></div>
                </div>

                <!-- 年龄分布 -->
                <div class="kol-data-section" v-if="hasDistributionData('age_distribution')">
                  <div class="section-title">年龄分布</div>
                  <div id="age-chart" class="chart-container"></div>
                </div>

                <!-- 设备分布 -->
                <div class="kol-data-section" v-if="hasDistributionData('device_distribution')">
                  <div class="section-title">设备分布</div>
                  <div id="device-chart" class="chart-container"></div>
                </div>

                <!-- 地域分布 -->
                <div class="kol-data-section" v-if="hasDistributionData('city_distribution')">
                  <div class="section-title">地域分布</div>
                  <div id="city-chart" class="chart-container"></div>
                </div>

                <!-- 城市等级 -->
                <div class="kol-data-section" v-if="hasDistributionData('city_level_distribution')">
                  <div class="section-title">城市等级分布</div>
                  <div id="city-level-chart" class="chart-container"></div>
                </div>

                <!-- 兴趣分布 -->
                <div class="kol-data-section" v-if="hasDistributionData('interest_distribution')">
                  <div class="section-title">兴趣分布</div>
                  <div id="interest-chart" class="chart-container"></div>
                </div>

                <!-- 视频内容 -->
                <div class="kol-data-section" v-if="hasDistributionData('video_distribution')">
                  <div class="section-title">视频内容分布</div>
                  <div id="video-distribution-chart" class="chart-container"></div>
                </div>

                <!-- 人群分布 -->
                <div class="kol-data-section" v-if="hasDistributionData('crowd_distribution')">
                  <div class="section-title">人群分布</div>
                  <div id="crowd-chart" class="chart-container"></div>
                </div>

                <!-- 全国省份分布排行 -->
                <div class="kol-data-section" v-if="hasDistributionData('province_distribution')">
                  <div class="section-title">全国省份分布排行</div>
                  <div id="china-map-chart" class="chart-container"></div>
                </div>
              </div>
            </div>

            <!-- 当粉丝画像数据为空时显示 -->
            <div class="kol-data-section" v-else-if="kolDetail && kolDetail.distribution_data">
              <div class="section-title">粉丝画像数据</div>
              <div class="empty-data-section">
                <el-empty description="暂无粉丝画像数据"></el-empty>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div v-else-if="!loading" class="no-data">
      <el-empty description="未找到达人数据"></el-empty>
    </div>
  </div>
</template>

<script>
import { getKolDetail, getKolVideos } from '@/api'
import * as echarts from 'echarts'
import QRCode from 'qrcodejs2'

export default {
  name: 'KolDetail',
  data () {
    return {
      loading: false,
      kolDetail: null,
      starId: this.$route.query.star_id,
      charts: {}, // 存储图表实例
      activeTab: 'overview', // 默认选中达人概览标签页

      // 作品列表相关数据
      videosLoading: false,
      videosList: [],
      sortField: 'watch_cnt',
      sortOrder: 'desc',
      pagination: {
        total: 0,
        page: 1,
        page_size: 20,
        total_pages: 0
      }
    }
  },
  created () {
    this.fetchKolDetail()
    // 将QRCode挂载到Vue实例上，方便在方法中使用
    this.$QRCode = QRCode
  },
  methods: {
    async fetchKolDetail () {
      if (!this.starId) {
        this.$message.error('缺少达人ID参数')
        return
      }

      this.loading = true
      try {
        const res = await getKolDetail({ star_id: this.starId })
        this.kolDetail = res.data

        // 获取达人作品列表
        this.fetchKolVideos()

        // 确保数据加载完成后再初始化图表
        this.$nextTick(() => {
          this.initCharts()
        })
      } catch (error) {
        console.error('获取达人详情失败:', error)
        this.$message.error('获取达人详情失败，请稍后重试')
      } finally {
        this.loading = false
      }
    },

    // 获取达人作品列表
    async fetchKolVideos () {
      if (!this.starId) return

      this.videosLoading = true
      try {
        const params = {
          star_id: this.starId,
          plat: this.kolDetail.plat,
          sort_field: this.sortField,
          sort_order: this.sortOrder,
          page: this.pagination.page,
          page_size: this.pagination.page_size
        }

        const res = await getKolVideos(params)
        // 过滤掉 B 站（plat=4）且内容为空的项
        if (this.kolDetail.plat === 4) {
          this.videosList = res.data.list.filter(item => {
            // 检查内容是否为空（标题为空或者没有内容）
            return item.title && item.title.trim() !== '' && item.title !== 'None';
          });
        } else {
          this.videosList = res.data.list;
        }
        this.pagination = res.data.pagination
      } catch (error) {
        console.error('获取达人作品列表失败:', error)
        this.$message.error('获取达人作品列表失败，请稍后重试')
      } finally {
        this.videosLoading = false
      }
    },

    // 分页处理
    handleSizeChange (size) {
      this.pagination.page_size = size
      this.pagination.page = 1
      this.fetchKolVideos()
    },

    handleCurrentChange (page) {
      this.pagination.page = page
      this.fetchKolVideos()
    },
    // 打开视频链接
    openVideoUrl (video) {
      // 如果是小红书平台，显示二维码
      if (this.kolDetail.plat === 2) {
        // 检查是否有item_id，如果没有则尝试从video_url中提取
        let itemId = video.item_id
        let xhsUrl = ''

        if (itemId) {
          // 直接使用item_id构建小红书链接
          xhsUrl = `xhsdiscover://video_feed/${itemId}?source=pcweb_access_limit`
        } else if (video.video_url) {
          // 尝试从video_url中提取item_id
          // 小红书URL格式可能是：https://www.xiaohongshu.com/explore/xxxxxxxxxxxxxxxx
          // 或者 https://www.xiaohongshu.com/discovery/item/xxxxxxxxxxxxxxxx
          const urlMatch = video.video_url.match(/\/([a-zA-Z0-9]{24})/)
          if (urlMatch && urlMatch[1]) {
            itemId = urlMatch[1]
            xhsUrl = `xhsdiscover://video_feed/${itemId}?source=pcweb_access_limit`
          } else {
            // 如果无法提取，直接使用原始URL
            xhsUrl = video.video_url
          }
        } else {
          this.$message.warning('视频链接不可用')
          return
        }

        // 生成唯一ID，避免多次打开二维码时ID冲突
        const qrcodeId = 'qrcode-' + Date.now()

        this.$alert(
          `<div class="qrcode-container">
            <div id="${qrcodeId}" class="qrcode-image"></div>
            <div class="qrcode-text">请通过小红书APP扫码查看</div>
          </div>`,
          '小红书',
          {
            dangerouslyUseHTMLString: true,
            showConfirmButton: false,
            showClose: true,
            customClass: 'xiaohongshu-qrcode-dialog',
            beforeClose: (action, instance, done) => {
              // 关闭前清理二维码元素内容，避免内存泄漏
              const qrcodeEl = document.getElementById(qrcodeId)
              if (qrcodeEl) {
                qrcodeEl.innerHTML = ''
              }
              done()
            }
          }
        )

        // 在下一个渲染周期生成二维码
        this.$nextTick(() => {
          const qrcodeEl = document.getElementById(qrcodeId)
          if (qrcodeEl) {
            new this.$QRCode(qrcodeEl, {
              text: xhsUrl,
              width: 180,
              height: 180,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: this.$QRCode.CorrectLevel.H
            })
          }
        })
      } else {
        // 其他平台直接打开链接
        if (video.video_url) {
          window.open(video.video_url, '_blank')
        } else {
          this.$message.warning('视频链接不可用')
        }
      }
    },

    getPlatformName (plat) {
      const platformMap = {
        1: '抖音',
        2: '小红书',
        3: '快手',
        4: 'B站',
        5: '视频号',
        6: '公众号',
        7: '微博'
      }
      return platformMap[plat] || '-'
    },
    formatNumber (num) {
      if (!num) return '-'

      // 处理正负数
      const isNegative = num < 0
      const absNum = Math.abs(num)

      let formattedNum
      if (absNum >= 10000) {
        formattedNum = (absNum / 10000).toFixed(1) + 'w'
      } else {
        formattedNum = absNum.toString()
      }

      // 如果是负数，添加负号
      return isNegative ? '-' + formattedNum : formattedNum
    },
    formatPercentage (value) {
      if (!value) return '-'
      return Number(value).toFixed(2) + '%'
    },
    calculateFansIncrementRate (totalFans, incrementFans) {
      if (!totalFans || !incrementFans) return '-'
      // 计算涨粉率 = 涨粉量 / 总粉丝数 * 100%
      const rate = (incrementFans / totalFans * 100).toFixed(2)
      return rate + '%'
    },
    getMainCategory (tags) {
      if (!tags) return '-'
      // 如果是数组(B站的情况)，直接返回第一个标签
      if (Array.isArray(tags)) {
        return tags[0] || '-'
      }
      // 其他平台的情况(对象格式)
      if (typeof tags === 'object') {
        return Object.keys(tags)[0] || '-'
      }
      // 如果是字符串，直接返回
      return tags
    },
    getTagsSubCategories (tags) {
      if (!tags) return ''
      // 如果是数组(B站的情况)，返回除第一个标签外的其他标签
      if (Array.isArray(tags)) {
        return tags.slice(1).join('、') || '-'
      }
      // 其他平台的情况(对象格式)
      if (typeof tags === 'object') {
        const mainCategory = Object.keys(tags)[0]
        if (!mainCategory || !Array.isArray(tags[mainCategory])) return ''
        return tags[mainCategory].join('、') || '-'
      }
      // 如果是字符串，返回空
      return ''
    },
    formatContentThemes (themes) {
      if (!themes) return []
      // 如果已经是数组，直接过滤掉 "None" 值
      if (Array.isArray(themes)) return themes.filter(theme => theme !== 'None')
      // 如果是字符串，按逗号或顿号分割，并过滤掉 "None" 值
      return themes.split(/[,，、]/).filter(item => item.trim() && item.trim() !== 'None')
    },
    // 打开平台主页
    openPlatformProfile (kol) {
      let url = ''
      switch (kol.plat) {
        case 1: // 抖音
          url = `https://www.xingtu.cn/ad/creator/author/douyin/${encodeURIComponent(kol.star_id)}`
          break
        case 2: // 小红书
          url = `https://www.xiaohongshu.com/search_result?keyword=${encodeURIComponent(kol.star_id)}&source=web_user_page&search_type=user`
          break
        case 3: // 快手
          url = `https://www.kuaishou.com/search/author?searchKey=${encodeURIComponent(kol.nick_name)}`
          break
        case 4: // B站
          url = `https://space.bilibili.com/${kol.star_id}`
          break
        case 5: // 视频号
          url = `https://channels.weixin.qq.com/${kol.star_id}`
          break
        case 6: // 公众号
          url = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${encodeURIComponent(kol.star_id)}`
          break
        case 7: // 微博
          url = `https://weibo.com/u/${kol.star_id}`
          break
        default:
          return
      }
      window.open(url, '_blank')
    },
    // 复制星图ID
    copyStarId () {
      if (this.kolDetail && this.kolDetail.star_id) {
        const input = document.createElement('input')
        input.value = this.kolDetail.star_id
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        document.body.removeChild(input)
        this.$message.success('ID已复制到剪贴板')
      }
    },
    getLocationDisplay (province, city) {
      if (province && city && province !== city) {
        return `${province} ${city}`
      } else if (province) {
        return province
      } else if (city) {
        return city
      } else {
        return '-'
      }
    },
    // 处理标签页切换
    handleTabChange (tab) {
      if (tab.name === 'fans' && this.kolDetail && this.kolDetail.distribution_data) {
        // 在下一个渲染周期初始化图表，确保DOM已经渲染
        this.$nextTick(() => {
          try {
            // 销毁现有图表实例，避免重复创建
            Object.values(this.charts).forEach(chart => {
              chart && chart.dispose()
            })
            this.charts = {}

            // 重新初始化图表
            this.initCharts()

            // 延迟一点时间再次调整图表大小，确保完全渲染
            setTimeout(() => {
              this.resizeCharts()
            }, 300)
          } catch (error) {
            console.error('切换标签页初始化图表时出错:', error)
          }
        })
      }
    },
    // 初始化图表相关方法
    initCharts () {
      if (!this.kolDetail || !this.kolDetail.distribution_data) {
        console.log('kolDetail或distribution_data不存在，无法初始化图表')
        return
      }

      // 初始化各个图表
      this.$nextTick(() => {
        try {
          if (this.hasDistributionData('gender_distribution')) {
            this.initGenderChart()
          }

          if (this.hasDistributionData('age_distribution')) {
            this.initAgeChart()
          }

          if (this.hasDistributionData('device_distribution')) {
            this.initDeviceChart()
          }

          if (this.hasDistributionData('city_distribution')) {
            this.initCityChart()
          }

          if (this.hasDistributionData('province_distribution')) {
            this.initProvinceChart()
            this.initChinaMapChart()
          }

          if (this.hasDistributionData('city_level_distribution')) {
            this.initCityLevelChart()
          }

          if (this.hasDistributionData('interest_distribution')) {
            this.initInterestChart()
          }

          if (this.hasDistributionData('video_distribution')) {
            this.initVideoDistributionChart()
          }

          if (this.hasDistributionData('crowd_distribution')) {
            this.initCrowdChart()
          }

          // 确保图表正确渲染
          setTimeout(() => {
            this.resizeCharts()
          }, 100)
        } catch (error) {
          console.error('初始化图表时出错:', error)
        }
      })
    },

    // 初始化设备分布图表
    initDeviceChart () {
      try {
        const chartDom = document.getElementById('device-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.device = chart

        const deviceData = this.kolDetail.distribution_data.device_distribution || []
        if (!deviceData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 根据平台类型分别处理数据
        let data = []

        // 对数据进行排序，按照数值从大到小
        const sortedData = [...deviceData].sort((a, b) => {
          return Number(b.distribution_value) - Number(a.distribution_value)
        })

        // 统一处理所有平台的数据
        data = sortedData.map(item => {
          // 处理设备品牌名称
          let deviceName = item.distribution_key
          // 对于iPhone特殊处理
          if (deviceName.toLowerCase() === 'iphone') {
            deviceName = '苹果'
          }

          return {
            name: deviceName,
            value: Number(item.distribution_value) // 直接使用返回的百分比数值
          }
        })

        // 根据平台设置不同的tooltip和label格式
        let tooltipFormatter = '{b}: {c}%';
        let labelFormatter = '{b}: {c}%';

        // 快手平台和小红书平台只显示百分比，不显示数值
        if (this.kolDetail.plat === 3 || this.kolDetail.plat === 2) {
          tooltipFormatter = '{b}: {d}%';
          labelFormatter = '{b}: {d}%';
        }

        let option = {
          tooltip: {
            trigger: 'item',
            formatter: tooltipFormatter
          },
          legend: {
            show: false, // 隐藏图例
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.name),
            textStyle: {
              fontSize: this.getResponsiveFontSize(12)
            }
          },
          series: [
            {
              name: '设备分布',
              type: 'pie',
              radius: this.isSmallScreen() ? ['30%', '60%'] : ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                formatter: labelFormatter,
                fontSize: this.getResponsiveFontSize(12)
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.getResponsiveFontSize(14),
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: data
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化设备分布图表时出错:', error)
      }
    },

    // 初始化中国地图
    initChinaMapChart () {
      const chartDom = document.getElementById('china-map-chart')
      if (!chartDom) return

      const chart = echarts.init(chartDom)
      this.charts.chinaMap = chart

      const provinceData = this.kolDetail.distribution_data.province_distribution || []
      if (!provinceData.length) {
        // 当没有数据时，显示空数据提示
        chart.setOption({
          title: {
            text: '暂无数据',
            left: 'center',
            top: 'center',
            textStyle: {
              color: '#909399',
              fontSize: 14
            }
          },
          xAxis: {
            type: 'category',
            data: []
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [],
              type: 'bar'
            }
          ]
        })
        return
      }

      // 处理数据，将省份名称映射到地图需要的格式
      const provinceNameMap = {
        '北京': '北京',
        '天津': '天津',
        '河北': '河北',
        '山西': '山西',
        '内蒙古': '内蒙古',
        '辽宁': '辽宁',
        '吉林': '吉林',
        '黑龙江': '黑龙江',
        '上海': '上海',
        '江苏': '江苏',
        '浙江': '浙江',
        '安徽': '安徽',
        '福建': '福建',
        '江西': '江西',
        '山东': '山东',
        '河南': '河南',
        '湖北': '湖北',
        '湖南': '湖南',
        '广东': '广东',
        '广西': '广西',
        '海南': '海南',
        '重庆': '重庆',
        '四川': '四川',
        '贵州': '贵州',
        '云南': '云南',
        '西藏': '西藏',
        '陕西': '陕西',
        '甘肃': '甘肃',
        '青海': '青海',
        '宁夏': '宁夏',
        '新疆': '新疆',
        '台湾': '台湾',
        '香港': '香港',
        '澳门': '澳门',
        '海外': '海外'
      }

      const data = provinceData.map(item => {
        const provinceName = item.distribution_key.replace('省', '').replace('市', '').replace('自治区', '').replace('维吾尔', '').replace('回族', '').replace('壮族', '')
        const mappedName = provinceNameMap[provinceName] || provinceName

        return {
          name: mappedName,
          value: this.getProvinceValue(item),
          percentage: this.formatProvincePercentage(item)
        }
      })

      // 使用柱状图替代地图
      const sortedData = [...data].sort((a, b) => b.value - a.value)
      // 只显示前8个省份，避免图表过于拥挤
      const topProvinces = sortedData.slice(0, 8)
      const xAxisData = topProvinces.map(item => item.name)
      const seriesData = topProvinces.map(item => ({
        value: item.value,
        percentage: item.percentage
      }))

      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            return `${params[0].name}: ${params[0].data.percentage}`
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisLabel: {
            interval: 0,
            rotate: 45,
            fontSize: this.getResponsiveFontSize(12)
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toFixed(2) + '%'
            },
            fontSize: this.getResponsiveFontSize(12)
          }
        },
        series: [
          {
            name: '省份分布',
            type: 'bar',
            data: seriesData,
            label: {
              show: true,
              position: 'top',
              formatter: function (params) {
                return params.data.percentage;
              },
              fontSize: this.getResponsiveFontSize(12)
            }
          }
        ]
      }

      chart.setOption(option)
    },

    // 初始化性别分布图表
    initGenderChart () {
      try {
        const chartDom = document.getElementById('gender-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.gender = chart

        const genderData = this.kolDetail.distribution_data.gender_distribution || []
        if (!genderData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 处理数据
        let maleValue = 0
        let femaleValue = 0

        // 统一处理所有平台的数据
        genderData.forEach(item => {
          if (item.distribution_key === 'male') {
            maleValue = Number(item.distribution_value)
          } else if (item.distribution_key === 'female') {
            femaleValue = Number(item.distribution_value)
          }
        })

        // 确保百分比总和为100%
        const total = maleValue + femaleValue
        if (total > 0) {
          maleValue = (maleValue / total * 100).toFixed(2)
          femaleValue = (femaleValue / total * 100).toFixed(2)
        }

        // 根据屏幕尺寸设置饼图半径
        let pieRadius = ['30%', '60%'];
        if (this.isExtraLargeScreen()) {
          pieRadius = ['30%', '60%'];
        } else if (this.isLargeScreen()) {
          pieRadius = ['30%', '65%'];
        } else if (this.isMediumScreen()) {
          pieRadius = ['35%', '70%'];
        } else {
          pieRadius = ['40%', '75%'];
        }

        // 使用饼图替代自定义图表
        const option = {
          backgroundColor: 'transparent',
          title: {
            left: 'center',
            top: 10,
            textStyle: {
              color: '#3498db',
              fontSize: this.getResponsiveFontSize(16),
              fontWeight: 'bold'
            },
            subtextStyle: {
              color: '#e74c3c',
              fontSize: this.getResponsiveFontSize(16),
              fontWeight: 'bold'
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}%'
          },
          legend: {
            show: false
          },
          series: [
            {
              type: 'pie',
              radius: pieRadius,
              center: ['50%', '55%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                formatter: '{b}: {c}%',
                fontSize: this.getResponsiveFontSize(12)
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.getResponsiveFontSize(14),
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: [
                {
                  value: maleValue,
                  name: '男性',
                  itemStyle: { color: '#3498db' }
                },
                {
                  value: femaleValue,
                  name: '女性',
                  itemStyle: { color: '#e74c3c' }
                }
              ]
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化性别分布图表时出错:', error)
      }
    },

    // 初始化年龄分布图表
    initAgeChart () {
      try {
        const chartDom = document.getElementById('age-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.age = chart

        const ageData = this.kolDetail.distribution_data.age_distribution || []
        if (!ageData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'funnel',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 处理数据
        let data = []

        // 统一处理所有平台的数据
        data = ageData.map(item => {
          return {
            name: item.distribution_key,
            value: Number(item.distribution_value) // 直接使用返回的百分比数值
          }
        })

        // 根据平台设置不同的tooltip和label格式
        let tooltipFormatter = '{b}: {c}%';
        let labelFormatter = '{b}: {c}%';

        // 抖音平台只显示百分比，不显示数值
        if (this.kolDetail.plat === 1) {
          tooltipFormatter = '{b}: {d}%';
          labelFormatter = '{b}: {d}%';
        }

        const option = {
          tooltip: {
            trigger: 'item',
            formatter: tooltipFormatter
          },
          legend: {
            show: false, // 隐藏图例
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.name),
            textStyle: {
              fontSize: this.getResponsiveFontSize(12)
            }
          },
          series: [
            {
              name: '年龄分布',
              type: 'pie',
              radius: this.isSmallScreen() ? ['30%', '60%'] : ['50%'],
              data: data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              },
              label: {
                show: true,
                formatter: labelFormatter,
                fontSize: this.getResponsiveFontSize(12)
              }
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化年龄分布图表时出错:', error)
      }
    },

    // 窗口大小变化时重新调整图表大小
    resizeCharts () {
      this.$nextTick(() => {
        Object.values(this.charts).forEach(chart => {
          if (chart) {
            try {
              // 先尝试简单的resize操作
              chart.resize();

              // 如果需要更新配置，使用更安全的方式
              try {
                // 获取当前配置
                const option = chart.getOption();
                if (!option) return;

                // 创建新的配置对象，避免直接修改原对象
                const newOption = {};

                // 更新字体大小
                if (option.xAxis && option.xAxis[0]) {
                  newOption.xAxis = [{
                    axisLabel: {
                      fontSize: this.getResponsiveFontSize(12)
                    }
                  }];
                }

                if (option.yAxis && option.yAxis[0]) {
                  newOption.yAxis = [{
                    axisLabel: {
                      fontSize: this.getResponsiveFontSize(12)
                    }
                  }];
                }

                if (option.legend && option.legend[0]) {
                  newOption.legend = [{
                    textStyle: {
                      fontSize: this.getResponsiveFontSize(12)
                    }
                  }];
                }

                if (option.series && option.series[0]) {
                  newOption.series = [];

                  option.series.forEach((series, index) => {
                    const newSeries = {};

                    if (series.type === 'pie') {
                      // 根据屏幕宽度调整饼图半径
                      if (window.innerWidth >= 2000) {
                        newSeries.radius = ['30%', '60%'];
                      } else if (window.innerWidth >= 1600) {
                        newSeries.radius = ['30%', '65%'];
                      } else if (window.innerWidth >= 1200) {
                        newSeries.radius = ['35%', '70%'];
                      } else {
                        newSeries.radius = ['40%', '75%'];
                      }

                      if (series.label) {
                        newSeries.label = {
                          fontSize: this.getResponsiveFontSize(12)
                        };
                      }
                    }

                    newOption.series[index] = newSeries;
                  });
                }

                // 使用setOption的notMerge=false选项，只更新指定的配置项
                chart.setOption(newOption, false);
              } catch (configError) {
                console.error('更新图表配置时出错:', configError);
                // 配置更新失败，但resize已经成功，不影响基本显示
              }
            } catch (error) {
              console.error('调整图表大小时出错:', error);
            }
          }
        });
      });
    },

    hasAnyDistributionData () {
      if (!this.kolDetail || !this.kolDetail.distribution_data) return false

      const distributionTypes = [
        'gender_distribution',
        'age_distribution',
        'device_distribution',
        'city_distribution',
        'province_distribution',
        'city_level_distribution',
        'interest_distribution',
        'video_distribution',
        'crowd_distribution'
      ]

      return distributionTypes.some(type => this.hasDistributionData(type))
    },

    hasDistributionData (type) {
      return this.kolDetail &&
        this.kolDetail.distribution_data &&
        this.kolDetail.distribution_data[type] &&
        Array.isArray(this.kolDetail.distribution_data[type]) &&
        this.kolDetail.distribution_data[type].length > 0
    },

    // 添加响应式辅助方法
    isSmallScreen () {
      return window.innerWidth <= 1200
    },

    isMediumScreen () {
      return window.innerWidth > 1200 && window.innerWidth <= 1600
    },

    isLargeScreen () {
      return window.innerWidth > 1600 && window.innerWidth <= 2000
    },

    isExtraLargeScreen () {
      return window.innerWidth > 2000
    },

    getResponsiveFontSize (baseSize = 14) {
      if (this.isExtraLargeScreen()) {
        return baseSize;
      } else if (this.isLargeScreen()) {
        return Math.max(baseSize - 1, 10);
      } else if (this.isMediumScreen()) {
        return Math.max(baseSize - 2, 10);
      } else {
        return Math.max(baseSize - 3, 9);
      }
    },

    // 初始化视频内容分布图表
    initVideoDistributionChart () {
      try {
        // 实现视频内容分布图表初始化逻辑
        const chartDom = document.getElementById('video-distribution-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.videoDistribution = chart

        const videoData = this.kolDetail.distribution_data.video_distribution || []
        if (!videoData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 处理数据
        let data = []

        // 统一处理所有平台的数据
        data = videoData.map(item => {
          return {
            name: item.distribution_key,
            value: Number(item.distribution_value) // 直接使用返回的百分比数值
          }
        })

        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}%'
          },
          legend: {
            show: false, // 隐藏图例
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.name),
            textStyle: {
              fontSize: this.getResponsiveFontSize(12)
            }
          },
          series: [
            {
              name: '视频内容分布',
              type: 'pie',
              radius: this.isSmallScreen() ? ['30%', '60%'] : ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                formatter: '{b}: {c}%',
                fontSize: this.getResponsiveFontSize(12)
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.getResponsiveFontSize(14),
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: data
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化视频内容分布图表时出错:', error)
      }
    },

    // 处理排序下拉菜单命令
    handleSortCommand (command) {
      const [field, order] = command.split(',');
      this.sortField = field;
      this.sortOrder = order;
      this.fetchKolVideos();
    },

    // 初始化兴趣分布图表
    initInterestChart () {
      try {
        const chartDom = document.getElementById('interest-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.interest = chart

        const interestData = this.kolDetail.distribution_data.interest_distribution || []
        if (!interestData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 处理数据
        let data = []

        // 统一处理所有平台的数据
        data = interestData.map(item => {
          return {
            name: item.distribution_key,
            value: Number(item.distribution_value) // 直接使用返回的百分比数值
          }
        })

        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}%'
          },
          legend: {
            show: false, // 隐藏图例
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.name),
            textStyle: {
              fontSize: this.getResponsiveFontSize(12)
            }
          },
          series: [
            {
              name: '兴趣分布',
              type: 'pie',
              radius: this.isSmallScreen() ? ['30%', '60%'] : ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                formatter: '{b}: {c}%',
                fontSize: this.getResponsiveFontSize(12)
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.getResponsiveFontSize(14),
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: data
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化兴趣分布图表时出错:', error)
      }
    },

    // 添加处理不同平台省份数据的方法
    getProvinceValue (item) {
      // 所有平台的数据格式都是百分比数值，直接返回
      return Number(item.distribution_value)
    },

    formatProvincePercentage (item) {
      // 所有平台的数据格式都是百分比数值，直接格式化
      return Number(item.distribution_value).toFixed(2) + '%'
    },

    // 初始化省份分布图表
    initProvinceChart () {
      try {
        // 实现省份分布图表初始化逻辑
        const chartDom = document.getElementById('province-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.province = chart

        const provinceData = this.kolDetail.distribution_data.province_distribution || []
        if (!provinceData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 处理数据
        const data = provinceData.slice(0, 10).map(item => {
          return {
            name: item.distribution_key.replace('省', '').replace('市', '').replace('自治区', ''),
            value: this.getProvinceValue(item) // 使用辅助方法获取省份数值
          }
        })

        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}%'
          },
          legend: {
            show: false, // 隐藏图例
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.name),
            textStyle: {
              fontSize: this.getResponsiveFontSize(12)
            }
          },
          series: [
            {
              name: '省份分布',
              type: 'pie',
              radius: this.isSmallScreen() ? ['30%', '60%'] : ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                formatter: '{b}: {c}%',
                fontSize: this.getResponsiveFontSize(12)
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.getResponsiveFontSize(14),
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: data
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化省份分布图表时出错:', error)
      }
    },

    // 初始化城市等级分布图表
    initCityLevelChart () {
      try {
        // 实现城市等级分布图表初始化逻辑
        const chartDom = document.getElementById('city-level-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.cityLevel = chart

        const cityLevelData = this.kolDetail.distribution_data.city_level_distribution || []
        if (!cityLevelData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 处理数据
        let data = []

        // 统一处理所有平台的数据
        data = cityLevelData.map(item => {
          return {
            name: item.distribution_key,
            value: Number(item.distribution_value) // 直接使用返回的百分比数值
          }
        })

        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}%'
          },
          legend: {
            show: false, // 隐藏图例
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.name),
            textStyle: {
              fontSize: this.getResponsiveFontSize(12)
            }
          },
          series: [
            {
              name: '城市等级分布',
              type: 'pie',
              radius: this.isSmallScreen() ? ['30%', '60%'] : ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                formatter: '{b}: {c}%',
                fontSize: this.getResponsiveFontSize(12)
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.getResponsiveFontSize(14),
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: data
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化城市等级分布图表时出错:', error)
      }
    },

    // 初始化人群分布图表
    initCrowdChart () {
      try {
        const chartDom = document.getElementById('crowd-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.crowd = chart

        const crowdData = this.kolDetail.distribution_data.crowd_distribution || []
        if (!crowdData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 处理数据
        let data = []

        // 统一处理所有平台的数据
        data = crowdData.map(item => {
          return {
            name: item.distribution_key,
            value: Number(item.distribution_value) // 直接使用返回的百分比数值
          }
        })

        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}%'
          },
          legend: {
            show: false, // 隐藏图例
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.name),
            textStyle: {
              fontSize: this.getResponsiveFontSize(12)
            }
          },
          series: [
            {
              name: '人群分布',
              type: 'pie',
              radius: this.isSmallScreen() ? ['30%', '60%'] : ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                formatter: '{b}: {c}%',
                fontSize: this.getResponsiveFontSize(12)
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.getResponsiveFontSize(14),
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: data
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化人群分布图表时出错:', error)
      }
    },
    // 初始化城市分布图表
    initCityChart () {
      try {
        const chartDom = document.getElementById('city-chart')
        if (!chartDom) return

        const chart = echarts.init(chartDom)
        this.charts.city = chart

        const cityData = this.kolDetail.distribution_data.city_distribution || []
        if (!cityData.length) {
          // 当没有数据时，显示空数据提示
          chart.setOption({
            title: {
              text: '暂无数据',
              left: 'center',
              top: 'center',
              textStyle: {
                color: '#909399',
                fontSize: 14
              }
            },
            series: [
              {
                type: 'pie',
                radius: '50%',
                data: [],
                silent: true
              }
            ]
          })
          return
        }

        // 处理数据
        const data = cityData.slice(0, 10).map(item => {
          return {
            name: item.distribution_key.replace('市', ''),
            value: Number(item.distribution_value)
          }
        })

        const option = {
          tooltip: {
            trigger: 'item',
            formatter: '{b}: {c}%'
          },
          legend: {
            show: false, // 隐藏图例
            orient: 'vertical',
            left: 'left',
            data: data.map(item => item.name),
            textStyle: {
              fontSize: this.getResponsiveFontSize(12)
            }
          },
          series: [
            {
              name: '城市分布',
              type: 'pie',
              radius: this.isSmallScreen() ? ['30%', '60%'] : ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: true,
                formatter: '{b}: {c}%',
                fontSize: this.getResponsiveFontSize(12)
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: this.getResponsiveFontSize(14),
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: true
              },
              data: data
            }
          ]
        }

        chart.setOption(option)
      } catch (error) {
        console.error('初始化城市分布图表时出错:', error)
      }
    },
  },

  mounted () {
    // 监听窗口大小变化
    window.addEventListener('resize', this.resizeCharts)

    // 添加标签页切换后的图表重绘逻辑
    this.$nextTick(() => {
      try {
        const tabsElement = document.querySelector('.kol-tabs')
        if (tabsElement) {
          const observer = new MutationObserver(() => {
            if (this.activeTab === 'fans' && this.kolDetail) {
              this.resizeCharts()
            }
          })
          observer.observe(tabsElement, { attributes: true, subtree: true, childList: true })
        }
      } catch (error) {
        console.error('监听标签页变化时出错:', error)
      }
    })
  },

  beforeDestroy () {
    // 移除事件监听
    window.removeEventListener('resize', this.resizeCharts)
    // 销毁图表实例
    try {
      Object.values(this.charts).forEach(chart => {
        chart && chart.dispose()
      })
    } catch (error) {
      console.error('销毁图表实例时出错:', error)
    }
  },

  watch: {
    kolDetail: {
      handler (newVal) {
        // 移除这里的initCharts调用，避免重复初始化
      },
      immediate: true
    }
  }
}
</script>

<style>
/* 全局样式覆盖 */
.xiaohongshu-qrcode-dialog {
  width: 350px !important;
  border-radius: 4px !important;
  overflow: hidden !important;
  text-align: center !important;
  padding-bottom: 0 !important;
  font-size: 14px !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  border: 1px solid #EBEEF5 !important;
  margin: 0 auto !important;
}

.xiaohongshu-qrcode-dialog .el-message-box__header {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #e8e8e8 !important;
  padding: 12px 15px !important;
  text-align: left !important;
  position: relative !important;
}

.xiaohongshu-qrcode-dialog .el-message-box__title {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #333 !important;
  line-height: 1 !important;
  margin: 0 !important;
}

.xiaohongshu-qrcode-dialog .el-message-box__headerbtn {
  top: 12px !important;
  right: 15px !important;
  position: absolute !important;
}

.xiaohongshu-qrcode-dialog .el-message-box__content {
  padding: 30px 20px !important;
  text-align: center !important;
}

.xiaohongshu-qrcode-dialog .qrcode-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
}

.xiaohongshu-qrcode-dialog .qrcode-image {
  width: 180px !important;
  height: 180px !important;
  margin-bottom: 30px !important;
  display: inline-block !important;
}

.xiaohongshu-qrcode-dialog .qrcode-text {
  font-size: 14px !important;
  color: #606266 !important;
  text-align: center !important;
}

/* 使用更高特异性覆盖Element UI的默认样式 */
html body .el-message-box.xiaohongshu-qrcode-dialog {
  width: 350px !important;
  border-radius: 4px !important;
  overflow: hidden !important;
}

html body .el-message-box.xiaohongshu-qrcode-dialog .el-message-box__header {
  background-color: #f5f5f5 !important;
  border-bottom: 1px solid #e8e8e8 !important;
  padding: 12px 15px !important;
}

html body .el-message-box.xiaohongshu-qrcode-dialog .el-message-box__title {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #333 !important;
}

html body .el-message-box.xiaohongshu-qrcode-dialog .el-message-box__content {
  padding: 30px 20px !important;
}
</style>

<style scoped>
.kol-detail-page {
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
  border-radius: 1%;
  max-width: 100%;
  overflow-x: hidden;
  /* 防止水平溢出 */
  box-sizing: border-box;
  /* 确保padding不会增加宽度 */
}

.breadcrumb-container {
  margin-bottom: 20px;
  padding: 10px 0;
  max-width: 100%;
  overflow: hidden;
  /* 防止面包屑溢出 */
}

.kol-detail-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  /* 允许在小屏幕上换行 */
  max-width: 100%;
}

.kol-detail-left {
  flex: 0.3;
  min-width: 300px;
  /* 设置最小宽度 */
  max-width: 100%;
  /* 在小屏幕上不超过容器宽度 */
}

.kol-detail-right {
  flex: 0.7;
  min-width: 300px;
  /* 设置最小宽度 */
  max-width: 100%;
  /* 在小屏幕上不超过容器宽度 */
}

.kol-basic-info {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #eee;
}

.kol-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
}

.kol-info-content {
  flex: 1;
  overflow: hidden;
}

.kol-name {
  margin: 0 0 5px 0;
  font-size: 18px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.kol-id {
  margin: 0;
  font-size: 13px;
  color: #666;
  display: flex;
  align-items: center;
}

.kol-id i {
  margin-left: 5px;
  cursor: pointer;
  color: #409EFF;
}

.kol-tags {
  margin-top: 5px;
  max-width: 100%;
  overflow: hidden;
}

.kol-tag {
  margin-right: 5px;
  margin-bottom: 5px;
}

.kol-update-time {
  font-size: 13px;
  color: #666;
  margin-bottom: 15px;
}

.platform-btn {
  width: 100%;
}

.kol-data-section {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  overflow-x: auto;
  /* 允许内容水平滚动而不是溢出 */
}

/* 粉丝画像标签页中的卡片样式 */
.el-tab-pane[name="fans"] .kol-data-section {
  margin-bottom: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-tab-pane[name="fans"] .chart-container {
  height: 350px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1;
}

.section-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.data-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  font-size: 14px;
}

.kol-detail-right .data-item {
  flex-direction: row;
  justify-content: space-between;
}

.data-label {
  color: #666;
  margin-bottom: 5px;
}

.kol-detail-right .data-label {
  margin-bottom: 0;
}

.data-value {
  font-weight: 500;
  color: #333;
}

.tags-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
  max-width: 100%;
  overflow: hidden;
}

.fans-data-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.fans-data-item {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  border-bottom: 1px solid #eee;
}

.fans-data-label {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.fans-data-value {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.kol-info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 15px;
}

.kol-info-item {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;
}

.info-item-label {
  font-size: 12px;
  color: #999;
  margin-bottom: 5px;
}

.info-item-value {
  font-size: 14px;
  color: #333;
  font-weight: 500;
  word-break: break-word;
  /* 允许长文本换行 */
}

.content-themes,
.sub-categories {
  margin-top: 15px;
}

.content-themes-label,
.sub-categories-label {
  font-size: 13px;
  color: #666;
  margin-bottom: 8px;
}

.sub-categories-value {
  font-size: 14px;
  color: #333;
  word-break: break-word;
  /* 允许长文本换行 */
}

.price-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.price-label {
  color: #666;
  font-size: 14px;
}

.price-value {
  font-weight: 800;
  color: #f23e31;
}

.info-label {
  color: #666;
  margin-right: 5px;
}

.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

/* 图表相关样式 */
.chart-container {
  height: 350px;
  width: 100%;
  margin-top: 15px;
  overflow: hidden;
  /* 防止图表溢出 */
  visibility: visible !important;
  /* 确保图表容器始终可见 */
}

/* 粉丝画像网格布局 */
.fans-portrait-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

/* 全国省份分布排行图表样式 */
.fans-portrait-grid #china-map-chart {
  height: 350px;
}

/* 小屏幕：1列 */
@media (min-width: 768px) {
  .fans-portrait-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .el-tab-pane[name="fans"] .chart-container {
    height: 350px;
  }

  .fans-portrait-grid #china-map-chart {
    height: 350px;
  }
}

/* 中等屏幕：2列 */
@media (min-width: 1200px) {
  .fans-portrait-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .el-tab-pane[name="fans"] .chart-container {
    height: 320px;
  }

  .fans-portrait-grid #china-map-chart {
    height: 320px;
  }
}

/* 大屏幕：3列 */
@media (min-width: 1600px) {
  .fans-portrait-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .el-tab-pane[name="fans"] .chart-container {
    height: 300px;
  }

  .fans-portrait-grid #china-map-chart {
    height: 300px;
  }
}

/* 超大屏幕：4列 */
@media (min-width: 2000px) {
  .fans-portrait-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .el-tab-pane[name="fans"] .chart-container {
    height: 280px;
  }

  .fans-portrait-grid #china-map-chart {
    height: 280px;
  }
}

.empty-data-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin-top: 20px;
}

/* 标签页样式 */
.kol-tabs {
  margin-bottom: 20px;
  width: 100%;
  overflow-x: hidden;
}

.kol-tabs>>>.el-tabs__header {
  margin-bottom: 20px;
}

.kol-tabs>>>.el-tabs__item {
  font-size: 16px;
  height: 50px;
  line-height: 50px;
}

.kol-tabs>>>.el-tabs__item.is-active {
  font-weight: 600;
}

/* 视频列表样式 */
.video-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  gap: 10px;
}

.video-content-cell {
  display: flex;
  flex-direction: column;
}

/* 当没有主题标签时，使标题垂直居中 */
.video-content-cell.no-themes {
  justify-content: center;
}

.video-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.video-title {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  cursor: pointer;
}

.video-title:hover {
  color: #666;
}

.video-themes-row {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
}

/* 表头排序样式 */
.table-header-with-sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #909399;
}

.el-dropdown-link:hover {
  color: #409EFF;
}

.pagination-container {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

/* 添加表格响应式样式 */
.el-table {
  max-width: 100%;
  overflow-x: auto;
}

/* 修复Element UI表格在小屏幕上的显示问题 */
.el-table__body,
.el-table__header {
  min-width: 100%;
}

/* 确保标签页内容在切换时正确显示 */
.el-tab-pane {
  min-height: 200px;
  width: 100%;
}

/* 二维码样式 */
.qrcode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.qrcode-image {
  width: 180px;
  height: 180px;
  margin-bottom: 30px;
  display: inline-block;
}

.qrcode-text {
  font-size: 14px;
  color: #606266;
  text-align: center;
}

/* 添加中等屏幕的响应式布局 */
@media (max-width: 1400px) {
  .chart-container {
    height: 300px;
  }

  .kol-detail-container {
    gap: 15px;
  }

  .section-title {
    margin-bottom: 10px;
    padding-bottom: 8px;
  }

  /* 调整表格在中等屏幕上的显示 */
  .el-table {
    width: 100%;
    overflow-x: auto;
  }
}

/* 移动端响应式布局 */
@media (max-width: 768px) {
  .kol-detail-page {
    padding: 10px;
    /* 减小页面内边距 */
  }

  .kol-detail-container {
    flex-direction: column;
  }

  .kol-detail-left,
  .kol-detail-right {
    flex: 1;
    width: 100%;
    min-width: auto;
    /* 移除最小宽度限制 */
  }

  .fans-data-grid {
    grid-template-columns: 1fr;
  }

  .kol-info-grid {
    grid-template-columns: 1fr;
  }

  .chart-container {
    height: 280px;
  }

  /* 调整表格在移动端的显示 */
  .kol-data-section {
    padding: 15px 10px;
    /* 减小内边距 */
  }

  /* 调整视频列表过滤器在移动端的显示 */
  .video-filter {
    flex-direction: column;
    align-items: flex-start;
  }

  .video-filter .el-select {
    width: 100%;
    margin-bottom: 10px;
  }

  /* 调整分页在移动端的显示 */
  .pagination-container {
    justify-content: center;
  }
}

/* 全国省份分布排行图表样式 */
#china-map-chart {
  height: 350px;
}
</style>