import { render, staticRenderFns } from "./KolDetail.vue?vue&type=template&id=531c391c&scoped=true"
import script from "./KolDetail.vue?vue&type=script&lang=js"
export * from "./KolDetail.vue?vue&type=script&lang=js"
import style0 from "./KolDetail.vue?vue&type=style&index=0&id=531c391c&prod&lang=css"
import style1 from "./KolDetail.vue?vue&type=style&index=1&id=531c391c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531c391c",
  null
  
)

export default component.exports